@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap');

* {
    background: $background;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    z-index: 2;
}

html, body {
    height: 100%;
    width: 100%;
}

main {
    width: 100%;
}


.without-styles { 
    background-color: transparent;
    text-decoration: none;
    color: $dark;
    padding: 8px 15px;
    border: 1px solid $dark; 
    transition: all 0.3s ease-out;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.1em;
    &:hover {
      background: $dark; 
      color: $background;
    }
}


.title__with-line {
  display: block;
  overflow: hidden;
  margin-left: 20%;

  @media screen and (max-width: 960px) { 
    margin: 0;
  }

}
.title__with-line > span{
  position: relative;
  display: inline-block;
}
.title__with-line > span:after{
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 2px solid;
  width: 300px; /* half of limiter*/
  margin: 0 20px;
}
.title__with-line > span:after{
  left: 100%;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  
}

.btn--primary {
  background-color: $background;
  color: $dark;
  border: 1px solid $dark;
}

.btn--outline {
  color: $dark;
  padding: 8px 20px;
  border: 1px solid $dark; 
  transition: all 0.3s ease-out;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.1em;
  text-indent: 2px;
  text-transform: capitalize;
  font-feature-settings: 'pnum' on, 'onum' on;
}

.btn--outline-center {
  color: $dark;
  border: 1px solid $dark; 
  transition: all 0.3s ease-out;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.1em;
  text-indent: 2px;
  text-transform: capitalize;
 
}


.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
  justify-content: center;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: $dark; 
  color: $background;

}

.mt-15 {
  margin-top: 15px;
}


.p__medium {
  font-weight: 500;
}
