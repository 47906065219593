.about__container {
    margin: 0 20%;
}

.about__title {
    display: flex;
    color: $dark;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    letter-spacing: 0.03em;
}

.about__content {
    display: flex;
    flex-direction: row;
}

.about__information {
    margin-top: 100px;
    margin-right: 20px;
    text-align: justify;
    color: $normal;
    font-style: normal;
    line-height: 21px;
        & a {
            text-decoration: none;
        }
}

.about__technologies {
    display: flex;
    margin-top: 30px;
}
.about__technologies ul li {
    margin-top: 10px;
}

.about__image {
    max-height: 500px;
    height: 100%;
    max-width: 375px;
    width: 100%;
    margin-top: 60px;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); ;
}



@media screen and (max-width: 960px) {
    
    .about__container {
        margin: 30% 30px;
    }


    .about__title {
        display: flex;
        color: $dark;
        font-weight: bold;
        font-size: 25px;
        letter-spacing: 0.03em;
    }

    .about__content {
        justify-content: center;
        align-items: center;
        
        flex-direction: column;
    }


    .about__information {
        background-color: transparent;
        margin-top: 50px;
    }


    .about__technologies {
        display: flex;
        margin-top: 30px;
        text-align: left;
    }
}