.experience__container {
    background-color: $light;
    padding: 100px;
}

.experience__title {
    background-color: transparent;
    display: flex;
    color: lighten($color: $dark, $amount: 0.8);
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    letter-spacing: 0.03em;
    margin-left: 20%;
}

.experience__line {
    display: inline-block;
    color: $dark;
    border-bottom:  2px solid lighten($color: $dark, $amount: 0.8) ;
    background-color: transparent;
    width: 300px; /* half of limiter*/
    margin: 25px 20px;
}

.experience__content {
    display: flex;
    background-color: transparent;    
    margin: 50px 20%;
    flex-direction: row;
}

.experience__jobs {
    background-color: transparent;
    margin-right: 20%;
    margin-top: 10px;
        & ul {
            background-color: transparent;
            
        }
}


.active {
    background-color: transparent;
    text-decoration: none;
    list-style: none;
    height: 30px;       
    border-left: 3px solid $dark;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 500;
    color: $dark;
}

.no-active {
    background-color: transparent;
    text-decoration: none;
    list-style: none;
    height: 30px;       
    border-left: 3px solid rgba($dark, 0.5);
    padding-left: 15px;
    font-size: 16px;
    font-weight: 500;
    color: rgba($dark, 0.7);
    cursor: pointer;
}

.experience__information {
    background-color: transparent;

        & ul {
            margin-top: 30px;
            background-color: transparent;
        }

        & li {
            background-color: transparent;
            margin-top: 40px;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            list-style: none;
        }
     

} 

.experience__information-title {
    background-color: transparent;
    font-weight: 500;
    font-size: 24px;
        & a {
            background-color: transparent;
            display: inline;
            color: $normal;
            cursor: pointer;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
                transition: all 2ms ease-in;
            }
        }
}

.experience__information-date {
    background-color: transparent;
    line-height: 25px;
    font-weight: normal;
    font-size: 14px;
}


.list-arrow {
    background-color: transparent;
    height: 10px;
    margin-right: 10px;
}

.experience__text {
    background-color: transparent;
    margin-top: 20px;
    font-size: 17px;
    line-height: 23px;
    text-align: justify;

        & a {
            background-color: transparent;
            color: $normal;
            font-weight: 900;
            cursor: pointer;
            text-decoration: none;
        }
        & span {
            background-color: transparent;
            display: inline-flex;
            color: #000;
            font-weight: bold;
        }
}




@media screen and ( max-width: 960px) {
    
    .experience__container {
        padding: 0px;
        padding-bottom: 50px;
    }

    .experience__title {
        background-color: transparent;
        display: flex;
        color: lighten($color: $dark, $amount: 0.8);
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        letter-spacing: 0.03em;
        margin-left: 20px;
        justify-content: center;
    }
    
    .experience__line {
        display: none;
        color: $dark;
        border-bottom:  2px solid lighten($color: $dark, $amount: 0.8) ;
        background-color: transparent;
        max-width: 100px; /* half of limiter*/
        width: 100%;
        margin: 0px 0px 10px 0px;
    }

    .experience__content {
        display: flex;
        background-color: transparent;    
        margin: 50px 10%;
        flex-direction: column;
    }

    .experience__jobs {
        background-color: transparent;
        margin-right: 20%;
        margin-top: 10px;
            & ul {
                display: flex;
                background-color: transparent;
                list-style-type: none;
                padding:0; 
                
                & li {
                    padding:0;
                    padding-right: 10px;
                    margin-bottom: 30px;

                }
            }
    }
    
    
    .active {
        background-color: transparent;
        text-decoration: none;
        list-style: none;
        height: 30px;       
        border-bottom: 3px solid $dark;
        border-left: none;
        padding-left: 15px;
        font-size: 16px;
        font-weight: 500;
        color: $dark;
    }
    
    .no-active {
        background-color: transparent;
        text-decoration: none;
        list-style: none;
        height: 30px;       
        border-bottom: 3px solid rgba($dark, 0.5);
        border-left: none;
        padding-left: 15px;
        font-size: 16px;
        font-weight: 500;
        color: rgba($dark, 0.7);
        cursor: pointer;

    }



}
