.contact__container {
    margin: 300px 10%;
}

.contact__title {
    text-align: center; 
    font-weight: bold;
    font-size: 40px;
    letter-spacing: 0.03em;
    color: rgba($dark, 0.8);
}

.contact__information {
    text-align: center; 
    letter-spacing: 0.03em;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: rgba($dark, 0.8);
    justify-content: center;
    align-items: center;
}

.contact__information-container {
    margin: 30px 20%;
   
}

.contact__information-button{
    display: flex;
    align-items: center;
    justify-content: center
}

// footer

.footer__information {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 0.1em;
    text-indent: 2px;
    font-feature-settings: 'pnum' on, 'onum' on;
    padding-bottom: 20px;
    color: rgba(0, 86, 145, 0.5);
}