.navbar {
  background: $background;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  position: sticky;
  z-index: 999;
  transition: top 0.3s; 
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  max-width: 1500px;
}

.navbar-name {
  color: $dark;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-indent: 2px;
  text-transform: capitalize;
  font-feature-settings: 'pnum' on, 'onum' on, 'cpsp' on;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-items {
  height: 80px;
}

.nav-links {
  color: $dark;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 0.5rem;
  height: 100%;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.1em;
  text-indent: 2px;
  text-transform: capitalize;
  font-feature-settings: 'pnum' on, 'onum' on;
}

.nav-links:hover {
  border-bottom: 4px solid $dark;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: $dark;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  
  
  .nav-menu {
    display: flex;
    padding-top: 10px;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100% ;
    opacity: 1;
    transition: all 0.5s ease;
    margin-right: 0;
    list-style: none;
    list-style-type: none;
    margin-left: 0px; 
    padding-left:0px;
  }

  .nav-menu.active-menu {
    background: $light;
    left: 0;
    opacity: 1;
    transition: all -.5 ease;
    z-index: 1;
  }
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-links {
    background-color: $light;
    color: $dark;
    text-align: center;
    padding: 1rem 0;
    width: 100%;
    display: table;
    
  }

  .nav-links:hover {
    background-color: darken($color: $light, $amount: 5);
    color: lighten($color: $dark, $amount: 5);
    border-radius: 0;
  }

  .fa-times {
    color: $dark;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    background-color: transparent;
    font-size: 1.5rem;
    color: $dark;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: $background;
    color: $dark;
    transition: 250ms;
  }
}
