.work__container {
    margin: 0 10%
}


.work__title {
    
    display: flex; 
    color: $dark;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    letter-spacing: 0.03em;
    margin: 0 20%;
    }




.work__feature {
    margin-top: 60px;
    margin-right: 40px; 
    align-items: flex-end;
    align-content: flex-end;
    color: $normal;
    text-align: end;
    background-color: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.03em;
}

.work__work-title {
    background-color: transparent;
    margin-right: 40px; 
    align-items: flex-end;
    align-content: flex-end;
    color: $normal;
    text-align: end;
    
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 47px;
    /* identical to box height */

    letter-spacing: 0.03em;
    text-indent: 2px;
    font-feature-settings: 'pnum' on, 'onum' on;
}

.work__work-information {
    background-color: transparent;
    margin-right: 100px;
    font-size: 18px;
    line-height: 21px;
    align-items: center;
    letter-spacing: 0.02em;
    text-align: justify;
    margin-top: 30px;
        & a {
            background-color: transparent;
            color: $normal;
            font-weight: 900;
            cursor: pointer;
        }
        & p {
            background-color: transparent;
        }
}

.work__technologies {
    background-color: transparent;
    position: relative;
    list-style: none;
    display: flex;
    font-family: 'Roboto Mono', monospace;
    line-height: 24px;
    font-weight: 400;
    position: relative;
    letter-spacing: 0.02em;
    align-items: auto;
    flex-wrap: wrap;
    margin-right: 30px;
    justify-content: space-around;    

        & li {
            background-color: transparent;
            
        }
}

// Work - card

.work__card-container {
    margin-top: 50px;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    position: relative;
    align-items: auto;
    background-color: $light;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 60vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 50px;
    grid-template-areas: 
        "work__imagen work__text";
}

.work__text {
    background-color: $light;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
        "grid_title"
        "grid_information";
}

.grid_title {
    background-color: transparent;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
        "work_feature"
        "work_name-title";
    grid-area: grid_title;
}

.work_feature {
    background-color: transparent;
    grid-area: work_feature;
}
.work_name-title {
    background-color: transparent;
    grid-area: work_name-title;
}

.grid_information {
    display: grid;
    background-color: $light;
    grid-template-columns: 1fr;
    grid-template-rows: 4fr 1fr;
    grid-template-areas:
        "work_information"
        "work_technologies";
    grid-area: grid_information;
    margin-right: 100px;
}

.work_information {
    background-color: transparent;
    grid-area: work_information;
    font-size: 18px;
    line-height: 21px;
    align-items: center;
    letter-spacing: 0.02em;
    text-align: justify;
        & a {
            background-color: transparent;
            color: $normal;
            font-weight: 900;
            cursor: pointer;
            text-decoration: none;

        }
        & p {
            background-color: transparent;
        }
}

.work_technologies {
    background-color: transparent;
    grid-area: work_technologies;
}


.work__imagen {
    background-color: transparent;
    grid-area: work__imagen;
    display: flex;
    margin: 5%;
    flex-wrap: nowrap;
        & img {
            justify-content: center;
            background-color: transparent;
            align-content: center;
            align-items: center;
            justify-self: center;
            width: 100%;
            height: auto;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
}

@media screen and ( max-width: 960px) {
    
    .work__card-container {
        margin-top: 50px;
        flex-direction: column;
        justify-content: space-around;
        align-content: center;
        position: relative;
        align-items: auto;
        background-color: $light;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 100%;
        height: 60%;
        display: grid;
        gap: 0px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr;
        grid-template-areas: 
            "work__imagen" 
            "work__text";
    }

    .work__imagen {
        background-color: transparent;
        grid-area: work__imagen;
            & img {
                justify-content: center;
                background-color: transparent;
                align-content: center;
                align-items: center;
                justify-self: center;
                width: 100%;
                height: 100%;
            }
    }
    
    .work__text {
        background-color: $light;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: .1fr 1fr;
        grid-template-areas: 
            "grid_title"
            "grid_information";
    }

    .grid_information {
        display: grid;
        background-color: $light;
        grid-template-columns: 1fr;
        grid-template-rows: 4fr 1fr;
        grid-template-areas:
            "work_information"
            "work_technologies";
        grid-area: grid_information;
        margin-right: 0px;
    }

    .work__feature {
        margin-top: 10px;
        margin-right: 0px; 
        align-items: center;
        align-content: center;
        color: $normal;
        text-align: center;
        background-color: transparent;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.03em;
    }
    
    
    .work__work-title {
        background-color: transparent;
        margin-right: 0px; 
        align-items: center;
        align-content: center;
        color: $normal;
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        line-height: 47px;
        /* identical to box height */

        letter-spacing: 0.03em;
        text-indent: 2px;
    }
    .work_information {
        background-color: transparent;
        grid-area: work_information;
        font-size: 18px;
        line-height: 27px;
        align-items: center;
        letter-spacing: 0.02em;
        text-align: justify;
            & a {
                background-color: transparent;
                color: $normal;
                font-weight: 900;
                cursor: pointer;
                text-decoration: none;

            }
            & p {
                margin: 50px 20px;
                background-color: transparent;
            }
    }

}
