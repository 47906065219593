.image__container {
    display: flex;
}

.image__table-images {
    display: flex;
    justify-items: unset;
    max-height: 450px;
    height: auto;
    max-width: 500px;
    width: 100%;
    background-color: transparent;
}

.image__laptop {
    position: absolute;
    transform: scale(-1, 1);
    max-height: 180px;
    max-width: 160px;
    height: auto;
    width: 100%;
    background: transparent;
    margin-left: 160px;
    margin-top: 30px;
}

.image__plant {
    background: transparent;
    position: absolute;
    height: auto;
    width: 6.5%;
    margin-left: 310px;
    margin-top: 120px;
}

// mobile


@media screen and (max-width: 960px) { 
    .image__container {
        display: flex;
    }
    
    .image__table-images {
        display: flex;
        justify-items: unset;
        height: auto;
        width: 100%;
    }
    
    .image__laptop {
        position: absolute;
        transform: scale(-1, 1);
        max-height: 130px;
        width: auto;
        height: 100%;
        background: transparent;
        margin: 5% 25%;
    }
    
    .image__plant {
        background: transparent;
        position: absolute;
        max-height: 75px;
        max-width: 75px;
        height: auto;
        width: 100%;
        margin: 15% 60%
    }
}